import React from 'react';
import Swiper from 'react-id-swiper';
import { Navigation } from 'swiper/dist/js/swiper.esm';
import PropTypes from 'prop-types';

import { ReactComponent as SquareFilled } from "static/assets/brand/shapes/square-filled.svg";
import { ReactComponent as RectangleFilled } from "static/assets/brand/shapes/rectangle-filled.svg";
import { ReactComponent as RectangleStriped } from "static/assets/brand/shapes/rectangle_striped.svg";
import { ReactComponent as SquareFilledYellow } from "static/assets/brand/shapes/square_filled_yellow.svg";
import { ReactComponent as CircleStripedRed } from "static/assets/brand/shapes/circle_striped_red.svg";
import { ReactComponent as CircleFilledBlue } from "static/assets/brand/shapes/circle_filled_blue.svg";

import './SliderTeam.scss';

const SliderTeam = (props) => {

  const { children, classes } = props;

  const getRandomIntInclusive = (min, max) => {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min);
  }
  const layout = getRandomIntInclusive(1, 2);

  const sliderSettings = {
    slidesPerView: 'auto',
    grabCursor: true,
    breakpointsInverse: true,
    breakpoints: {
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      }
    },
  };

  return (
    <div className="section team-slider section-swiper container p-0">
      <div className="row">
        <div className="col-12 p-0 swiper-row">
          {layout === 1 && (
            <div className="layout layout-1">
              <RectangleStriped className="shape-1" />
              <CircleFilledBlue className="shape-2" />
              <SquareFilledYellow className="shape-3" />
              <CircleStripedRed className="shape-4" />
            </div>
          )}
          {layout === 2 && (
            <div className="layout layout-2">
              <RectangleFilled className="shape-5" />
              <SquareFilledYellow className="shape-6" />
              <CircleStripedRed className="shape-7" />
              <SquareFilled className="shape-8" />
            </div>
          )}
          <Swiper
            modules={[Navigation]}
            {...sliderSettings}
            containerClass={classes}
          >
            { children }
          </Swiper>
        </div>
      </div>
    </div>
  )

}

SliderTeam.propTypes = {
  settings: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
};

SliderTeam.defaultProps = {
  settings: {
    grabCursor: true,
    slidesPerView: 'auto',
    loop: false,
  }
};

export default SliderTeam