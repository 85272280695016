import React from "react";
import classNames from "classnames";
import "./IntroText.scss";

const IntroText = (props) => {
  const { text, isBig } = props;

  return (
    <section className="container-fluid section intro-text-section pt-1 pb-5">
      <div className="container">
        {
          text && (
            <div className="row justify-content-center">
              <div
                className={
                  classNames("col-12 col-md-10 col-lg-8 mx-auto text-align-center", isBig ? "text-l" : "text-m")
                }
              >
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </div>
          )
        }
      </div>
    </section>
  );
}

export default IntroText