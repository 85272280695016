import React from 'react';
import HeadlineAndText from 'Accessories/HeadlineAndText/HeadlineAndText';
import SliderTeam from 'Layout/Slider/SliderTeam/SliderTeam';
import './TeamSection.scss';

const TeamSection = (props) => {

  const { headline, teamMembers } = props

  return (
    <div className="section team-section container-fluid pt-md-3 pb-10 pb-md-0">
      <div className="container">
        <div className="row text-align-center">
          <div className="col-12">
            <HeadlineAndText headline={headline} isSubHeadline />
          </div>
        </div>
        <div className="row">
          <SliderTeam>
            {
              teamMembers.length > 0 && teamMembers.map((member, index) => (
                <div className={`team-member ${ index === 0 ? 'ml-1' : 'ml-2'}`} key={member.name}>
                  {
                    member.image && member.image.localFile && <img className="team-member-image d-block mb-1 rounded-edges" src={member.image.localFile.childImageSharp.fluid.src} alt={member.name} />
                  }
                  <div className="team-member-text">
                    {
                      member.name && <h3 className="text-l mb-1">{member.name}</h3>
                    }
                    <p>
                      {member.position && member.position}
                      {
                        member.email && (
                          <>
                            <br />
                            <span>Email: </span>
                            <a href={`mailto: ${member.email}`}>{member.email}</a>
                          </>
                        ) 
                      }
                      {
                        member.phone && (
                          <>
                            <br />
                            <a href={`tel: ${member.phone}`}>{member.phone}</a>
                          </>
                        )
                      }
                    </p>
                  </div>
                </div>
              ))
            }
          </SliderTeam>
        </div>
      </div>
    </div>
  )
}

export default TeamSection