import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Hero from "Layout/Hero/Hero"
import MainLayout from "Layout/layout";
import Footer from "Layout/Footer/Footer";
import TeamSection from "Layout/TeamSection/TeamSection";
import SliderAttraction from "Layout/Slider/SliderAttraction/SliderAttraction";
import IntroText from "Layout/IntroText/IntroText";
import config from "data/SiteConfig";

const About = (props) => {
  const { location, pageContext, data } = props;
  const { page, optionsPage } = data;

  return (
    <MainLayout location={location} pageContext={pageContext}>
      <Helmet
        meta={[
          {
            property: 'og:title',
            content: page.acf.meta_info_title,
          },
          {
            property: 'og:description',
            content: page.acf.meta_info_description,
          },
          {
            property: 'og:image',
            content: page.acf.social_share_image.image.localFile.childImageSharp.fluid.src,
          },
          {
            property: 'og:url',
            content: location.href,
          },
          {
            property: 'og:site_name',
            content: config.siteTitle,
          },
          {
            name: 'twitter:image:alt',
            content: page.acf.meta_info_description,
          }
        ]}
      />
      <Hero
        headline={page.acf.hero.headline}
        text={page.acf.hero.subheadline}
        openingTimes={optionsPage.edges[0].node.options.opening_times}
        waitingTime={optionsPage.edges[0].node.options.news.current_waiting_time}
      />
      {
        page.acf.slider.sliders.length > 0 && page.acf.slider.sliders.map(slider => (
          <SliderAttraction
            headline={slider.headline}
            subHeadline={slider.subheadline}
            link={slider.slider_link}
          >
            {
              slider.slides.length > 0 && slider.slides.map((slide, index) => (
                slide.image && slide.image.localFile &&  (
                  <div className={`d-flex ml-md-0 justify-content-center ${ index === 0 ? 'ml-1' : 'ml-2'}`}>
                    <div className="d-block rounded-edges overflow-hidden">
                      <img className="d-block mb-1" src={slide.image.localFile.childImageSharp.fluid.src} alt="Attraktion" />
                    </div>
                  </div>
                )
              ))
            }
          </SliderAttraction>
        ))
      }
      <IntroText text={page.acf.info_paragraph.text} />
      {page.acf.team.team_members && page.acf.team.team_members[0].image &&
        <TeamSection teamMembers={page.acf.team.team_members} classes="pb-10" />
      }
      <Footer />
    </MainLayout>
  );
}

export const query = graphql`
  query AboutPageQuery($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      title
      slug
      acf {
        hero {
          headline
          subheadline
        }
        info_paragraph {
          text
        }
        slider {
          sliders {
            slides {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1366) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
        team {
          team_members {
            name
            position
            email
            phone
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 683) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
        meta_info_title
        meta_info_description
        social_share_image {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          options {
            opening_times {
              opening_times_headline
              opening_times_additional_notice
              opening_times {
                opening_time_item
              }
            }
            news {
              current_waiting_time
            }
          }
        }
      }
    }
  }
`;

export default About